import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { addBondUser, formatNumber, getAllBonds } from "../../firebaseConfig/firestore";
import LoadingScreen from "../LoadingScreen";
import AddBondModal from "./Modal/AddBondModal";
import EditBondModal from "./Modal/EditBondModal";
import "./style.css";

const AddBond = ({ userId, onClose, refreshDetails }) => {
  const [bonds, setBonds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBond, setSelectedBond] = useState(null);
  const [visibleDropdownIndex, setVisibleDropdownIndex] = useState(null);
  const [bondModalOpen, setBondModalOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  const fetchBonds = async () => {
    try {
      setIsLoading(true);
      let fetchedBonds = await getAllBonds();
      fetchedBonds = fetchedBonds.sort((a, b) => a.index - b.index);
      setBonds(fetchedBonds);
      
    } catch (error) {
      console.error("Error fetching bonds:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBonds();
  }, []);

  const handleInvestSuccess = (investmentData, bondsId) => {
    setBondModalOpen(false);
    setIsEditing(true);
    setSelectedId(bondsId);
    setSelectedForEdit(investmentData);
  };

  const toggleDropdown = (index) => {
    if (visibleDropdownIndex === index) {
      setVisibleDropdownIndex(null); // if clicked again on the open dropdown, close it
    } else {
      setVisibleDropdownIndex(index); // open the clicked dropdown and close any other open dropdown
    }
  };

  return (
    <div className="bondsPage_Wrapper">
        <div className="headerSection">
        <h2 className="title">Choose Bonds</h2>
        <div className="svgWrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      <div className="contentBody">
        {isLoading && (
          <LoadingScreen />
        )}
          {!bonds || bonds.length === 0? (
          <h5 className="no_data">NO BONDS FOUND.</h5>
        ) : (
          bonds.map((bond) => (
            <div
              key={bond.index}
              className="bondCard"
              onClick={() => toggleDropdown(bond.index)}
            >
              <div className="bondDetails">
                <div className="bondColumn">
                  <div className="issuerImageWrapper">
                    <img src={bond.image} alt="Logo" />
                    
                  </div>
                  <div className="columnDetails">
                    <div className="issuerDetails">
                      <p className="issuerName"> {bond.issuerName} </p>
                      <div className="bondTypeWrapper">
                        <span className="bondType"> {bond.type} </span>
                      </div>
                    </div>
                    <div className="additionalDetails">
                      <div className="details">
                        <p className="boldText">Sector:</p>
                        <span className="regularText"> {bond.sector} </span>
                      </div>
                      <div className="details">
                          <p className="boldText">Maturity Date:</p>
                          <span className="regularText">
                            {bond.maturityDate}
                          </span>
                        </div>
                        <div className="details">
                          <p className="boldText">Minimum Investment:</p>
                          <span className="regularText">
                            $ {formatNumber(bond.minimumAmount)}
                          </span>
                        </div>
                      <div className="details">
                          <p className="boldText">ISIN:</p>
                          <span className="regularText">{bond.isin}</span>
                        </div>
                        <div className="details">
                          <p className="boldText">Coupon Frequency:</p>
                          <span className="regularText">
                            $ {formatNumber(bond.couponFrequency)}
                          </span>
                      </div>
                    </div>
                  </div>
                  <div className="couponDetails">
                    <div className="couponWrapper">
                      <div className="couponRate">
                        <span className="couponNumber">
                          {" "}
                          {bond.couponRate}{" "}
                        </span>
                        <span className="percentageSymbol">%</span>
                      </div>
                      <span className="regularText">Coupon</span>
                    </div>
                  </div>
                  <div className="purchaseButtonWrapper">
                      <button
                        onClick={() => {
                          setBondModalOpen(true);
                          setSelectedBond(bond);
                        }}
                        className="purchaseButton"
                      >
                        Add bond
                      </button>
                    </div>
                </div>
              </div>
            </div>
          ))
          )}
          {bondModalOpen && (
            <AddBondModal
              onClose={() => {
                setBondModalOpen(false);
                setSelectedBond(null);
              }}
              bond={selectedBond}
              userId={userId}
              onInvestSuccess={handleInvestSuccess}
            />
          )}
          {isEditing && (
            <EditBondModal
              bondId={selectedId}
              bond={selectedForEdit}
              onClose={onClose}
              userId={userId}
              refreshDetails={refreshDetails}
            />
          )}
      </div>
      <div style={{ marginTop: "30px" }}>
        <input
          style={{ marginLeft: "12px" }}
          className="muted-button"
          type="button"
          value="Close"
          onClick={() => {
            fetchBonds();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default AddBond;
